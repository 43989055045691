import _ from "lodash"
import React, { useState, useEffect } from "react"
import { Div, Input, Text, Icon, Image, Button } from "atomize"

import axios from "axios"
import * as yup from "yup"
import { Formik } from "formik"
import BlueButton from "../common/molecules/BlueButton"

const EditCardInfo = ({
  setActivePage,
  activeBilling,
  setActiveBilling,
  errors,
  setErrors,
  setData,
  data,
}) => {
  const [message, setMessage] = useState(null)
  const [formSubmitting, setFormSubmitting] = useState(false)
  let formikProps
  //console.log(data);
  const initialValues = {
    firstName: "",
    lastName: "",
  }

  const onSubmit = values => {
   setFormSubmitting(true)
    const datas ={
     cardholderName: values.cardholderName,
    cardNumber:values.cardNumber,
    expiryMonth:values.expiryMonth,
    expiryYear:values.expiryYear,
    cvv:values.cvv,
    zipCode:values.zipCode,
    }
   // console.log(datas);
    setFormSubmitting(false)
    setMessage("Your changes have been saved.")
  }

  const validator = yup.object().shape({
    firstName: yup
      .string()
      .required("Please use a valid name.")
      .min(1)
      .email("Please use a valid name."),
    lastName: yup
      .string()
      .required("Please use a valid name.")
      .min(1)
      .email("Please use a valid name."),
  })
  return (
    <>
      <Div
        p={{
          y: { xs: "1.875rem", lg: "2.5rem" },
          x: { xs: "1.25rem", lg: "2.5rem" },
        }}
        bg="yellow"
        rounded="lg"
        shadow="4"
        maxW="765px"
      >
        <Text
          textWeight="bold"
          textSize="display1"
          fontFamily="secondary"
          p={{ b: { xs: "1.875rem", lg: "2.5rem" } }}
          m={{ b: "2.5rem" }}
          style={{ lineHeight: "32px" }}
          border={{ b: "1px solid" }}
          borderColor="borderColor"
          textAlign={{ xs: "center", lg: "left" }}
        >
          Card on file
        </Text>
        {message && (
          <>
            <Div
              border={{ t: "1px solid" }}
              borderColor="borderColor"
              p={{ y: "2.5rem" }}
              d={{ xs: "block", md: "flex" }}
            >
              <Text textColor="success700" m={{ t: "1rem" }}>
                {message}
              </Text>
            </Div>
          </>
        )}
        {!message && (
        <Formik
          initialValues={{
            cardholderName: data?.cardholder_name || "",
            cardNumber: data?.card_last4 || "",
            expiryMonth: data?.card_exp_month || "",
            expiryYear: data?.card_exp_year || "",
            cvv: data?.cvv || "",
            zipCode: data.billing_address?.zip || "",
          }}
          onSubmit={onSubmit}
          //validationSchema={validator}
        >
          {props => {
            const {
              values,
              status,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props
            formikProps = props
            return (
              <form onSubmit={handleSubmit}>
                <Div d="flex" maxW="50%" m={{ t: "1.25rem" }} flexDir="column">
                  {/* {status && status.successMessage && (
                    <Text
                      textColor="success700"
                      m={{ b: "1rem" }}
                      opacity={status.successMessage ? "1" : "0"}
                      transition
                    >
                      {status.successMessage}
                    </Text>
                  )}
                  {status && status.errorMessage && (
                    <Text
                      textColor="danger700"
                      m={{ b: "1rem" }}
                      opacity={status.errorMessage ? "1" : "0"}
                      transition
                    >
                      {status.errorMessage}
                    </Text>
                  )} */}
                  <Div m={{ b: "1rem" }}>
                    <Text textWeight="bold" m={{ b: "10px" }}>
                      Name on Card
                    </Text>
                    <Input
                      type="text"
                      name="cardholderName"
                      placeholder="Cardholder Name"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"
                      h="2.5rem"
                      border="1px solid"
                      borderColor={
                        touched.cardholderName
                          ? values.cardholderName
                            ? errors.cardholderName
                              ? "red"
                              : "oilGreen"
                            : errors.cardholderName
                            ? "red"
                            : "transparent"
                          : "transparent"
                      }
                      p="0 2.125rem 0 2.125rem"
                      prefix={
                        <Div
                          d="flex"
                          align="center"
                          transform="translateY(-50%)"
                          pos="absolute"
                          top="50%"
                          left="0.75rem"
                        >
                          {/* <Image src="/phone.svg" w="14px" h="14px" /> */}
                        </Div>
                      }
                      value={values.cardholderName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <Text
                      textColor="error"
                      textSize="paragraph"
                      m={{ t: ".5rem" }}
                    >
                      {touched.cardholderName &&
                        _.capitalize(errors.cardholderName)}
                    </Text>
                  </Div>
                  <Div m={{ b: "1rem" }}>
                    <Text textWeight="bold" m={{ b: "10px" }}>
                      Card Number
                    </Text>
                    <Input
                      type="text"
                      name="cardNumber"
                      placeholder="Card Number"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"
                      h="2.5rem"
                      border="1px solid"
                      borderColor={
                        touched.cardNumber
                          ? values.cardNumber
                            ? errors.cardNumber
                              ? "red"
                              : "oilGreen"
                            : errors.cardNumber
                            ? "red"
                            : "transparent"
                          : "transparent"
                      }
                      p="0 2.125rem"
                      prefix={
                        <Image
                          src="/email.svg"
                          w="14px"
                          h="10px"
                          pos="absolute"
                          top="50%"
                          left="0.75rem"
                          transform="translateY(-50%)"
                        />
                      }
                      value={values.cardNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Text
                      textColor="error"
                      textSize="paragraph"
                      m={{ t: ".5rem" }}
                    >
                      {touched.cardNumber && _.capitalize(errors.cardNumber)}
                    </Text>
                  </Div>

                  <Div m={{ b: "1rem" }}>
                    <Text textWeight="bold" m={{ b: "10px" }}>
                      Expiration Month
                    </Text>
                    <Input
                      type="text"
                      name="expiryMonth"
                      placeholder="Expiration Month"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"
                      h="2.5rem"
                      border="1px solid"
                      borderColor={
                        touched.expiryMonth
                          ? values.expiryMonth
                            ? errors.expiryMonth
                              ? "red"
                              : "oilGreen"
                            : errors.expiryMonth
                            ? "red"
                            : "transparent"
                          : "transparent"
                      }
                      p="0 2.125rem"
                      prefix={
                        <Image
                          //   src="/expiryMonth.svg"
                          w="14px"
                          h="10px"
                          pos="absolute"
                          top="50%"
                          left="0.75rem"
                          transform="translateY(-50%)"
                        />
                      }
                      value={values.expiryMonth}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Text
                      textColor="error"
                      textSize="paragraph"
                      m={{ t: ".5rem" }}
                    >
                      {touched.expiryMonth && _.capitalize(errors.expiryMonth)}
                    </Text>
                  </Div>

                  <Div m={{ b: "1rem" }}>
                    <Text textWeight="bold" m={{ b: "10px" }}>
                      Expiration Year
                    </Text>
                    <Input
                      type="text"
                      name="expiryYear"
                      placeholder="Expiration Year"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"
                      h="2.5rem"
                      border="1px solid"
                      borderColor={
                        touched.expiryYear
                          ? values.expiryYear
                            ? errors.expiryYear
                              ? "red"
                              : "oilGreen"
                            : errors.expiryYear
                            ? "red"
                            : "transparent"
                          : "transparent"
                      }
                      p="0 2.125rem"
                      prefix={
                        <Image
                          //   src="/expiryYear.svg"
                          w="14px"
                          h="10px"
                          pos="absolute"
                          top="50%"
                          left="0.75rem"
                          transform="translateY(-50%)"
                        />
                      }
                      value={values.expiryYear}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Text
                      textColor="error"
                      textSize="paragraph"
                      m={{ t: ".5rem" }}
                    >
                      {touched.expiryYear && _.capitalize(errors.expiryYear)}
                    </Text>
                  </Div>
                  <Div m={{ b: "1rem" }}>
                    <Text textWeight="bold" m={{ b: "10px" }}>
                      CVV
                    </Text>
                    <Input
                      type="text"
                      name="cvv"
                      placeholder="CVV"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"
                      h="2.5rem"
                      border="1px solid"
                      borderColor={
                        touched.cvv
                          ? values.cvv
                            ? errors.cvv
                              ? "red"
                              : "oilGreen"
                            : errors.cvv
                            ? "red"
                            : "transparent"
                          : "transparent"
                      }
                      p="0 2.125rem"
                      prefix={
                        <Image
                          //   src="/cvv.svg"
                          w="14px"
                          h="10px"
                          pos="absolute"
                          top="50%"
                          left="0.75rem"
                          transform="translateY(-50%)"
                        />
                      }
                      value={values.cvv}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Text
                      textColor="error"
                      textSize="paragraph"
                      m={{ t: ".5rem" }}
                    >
                      {touched.cvv && _.capitalize(errors.cvv)}
                    </Text>
                  </Div>

                  <Div m={{ b: "1rem" }}>
                    <Text textWeight="bold" m={{ b: "10px" }}>
                      Zip Code
                    </Text>
                    <Input
                      type="text"
                      name="zipCode"
                      placeholder="Zip Code"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"
                      h="2.5rem"
                      border="1px solid"
                      borderColor={
                        touched.zipCode
                          ? values.zipCode
                            ? errors.zipCode
                              ? "red"
                              : "oilGreen"
                            : errors.zipCode
                            ? "red"
                            : "transparent"
                          : "transparent"
                      }
                      p="0 2.125rem"
                      prefix={
                        <Image
                          //   src="/zipCode.svg"
                          w="14px"
                          h="10px"
                          pos="absolute"
                          top="50%"
                          left="0.75rem"
                          transform="translateY(-50%)"
                        />
                      }
                      value={values.zipCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Text
                      textColor="error"
                      textSize="paragraph"
                      m={{ t: ".5rem" }}
                    >
                      {touched.zipCode && _.capitalize(errors.zipCode)}
                    </Text>
                  </Div>
                </Div>
                <BlueButton
                  m={{ y: "1rem" }}
                  type="submit"
                  disabled={formSubmitting}
                  text="Update Card"
                  //   text={
                  //     isSubmitting ? (
                  //       <Icon name="Loading" color="white" size="16px" />
                  //     ) : (
                  //       "Sign Up"
                  //     )
                  //   }
                  padding="0.5rem 1.25rem"
                  textSize="header1"
                  h="2.5rem"
                />
              </form>
            )
          }}
        </Formik>
        )}
        <Text m={{ t: "1rem" }} textWeight="bold" textDecor="underline" cursor="pointer" onClick={() => {
                        setActivePage("home")
                        setActiveBilling(null)
                      }}>
          Return to billing information
        </Text>
      </Div>
    </>
  )
}

export default EditCardInfo
