import React,{useState} from "react"
import { get, capitalize } from "lodash"
import ls from "local-storage"
import { Formik } from "formik"
import { useQuery, gql, useMutation } from "@apollo/client"
import { Div, Text, Input, Image, Button, Icon } from "atomize"

import { updatePasswordValidator } from "../../validators"
import { CUSTOMER_UPDATE_PASSWORD } from "../../graphql/queries"
import BlueButton from "../../components/common/molecules/BlueButton"
import { firePostUpdatePassowrdEvents } from "../../services/authService"

const UpdatePassword = ({ toggleTab }) => {
const [showSuccessMsg,setShowSuccessMsg]= useState(false);
  let formikProps

  const [updatePassword] = useMutation(CUSTOMER_UPDATE_PASSWORD, {
    onCompleted: result => {
      const { setSubmitting, resetForm } = formikProps
      setSubmitting(false)
      firePostUpdatePassowrdEvents(result)
      resetForm()
      handleShowSuccesMsg()
    },
  })

  /**
   * on submit form
   *
   * @param {*} values
   */
  const onSubmit = values => {
    updatePassword({
      variables: {
        customerAccessToken: ls("accessToken"),
        customer: { password: values.password },
      },
    })
  }

  const handleShowSuccesMsg = () => {
    setShowSuccessMsg(true);
    setTimeout(()=>setShowSuccessMsg(false),2000)
  }

  return (
    <>
      <Div
        p={{
          y: { xs: "1.875rem", lg: "2.5rem" },
          x: { xs: "1.25rem", lg: "2.5rem" },
        }}
        bg="yellow"
        rounded="lg"
        shadow="4"
      >
        <Text
          textWeight="bold"
          textSize="display1"
          fontFamily="secondary"
          p={{ b: { xs: "1.875rem", lg: "2.5rem" } }}
          style={{ lineHeight: "32px" }}
          border={{ b: "1px solid" }}
          borderColor="borderColor"
          textAlign={{ xs: "center", lg: "left" }}
        >
          Update Password
        </Text>
        <Formik
          initialValues={{
            password: "",
            password_confirmation: "",
          }}
          onSubmit={onSubmit}
          validationSchema={updatePasswordValidator}
        >
          {props => {
            const {
              values,
              status,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              submitForm,
            } = props

            formikProps = props

            return (
              <form onSubmit={handleSubmit}>
               {showSuccessMsg && (
                  <Text
                    textColor="success700"
                    m={{ t: "1rem" }}
                    opacity={showSuccessMsg ? "1" : "0"}
                    transition
                  >
                    Your Password has been updated!
                  </Text>
                )}
                <Div
                  m={{ t: "2.4rem" }}
                  // d="flex" flexWrap="wrap" maxW="70%"
                  className="account-card-input"
                >
                  <Div>
                    <Input
                      type="password"
                      name="password"
                      placeholder="Password"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"
                      h="2.5rem"
                      border="1px solid"
                      borderColor="transparent"
                      borderColor={
                        touched.password
                          ? values.password
                            ? errors.password
                              ? "red"
                              : "oilGreen"
                            : errors.password
                            ? "red"
                            : "transparent"
                          : "transparent"
                      }
                      p="0 2.125rem"
                      prefix={
                        <Image
                          src="/name.svg"
                          w="14px"
                          h="14px"
                          pos="absolute"
                          top="50%"
                          left="0.75rem"
                          transform="translateY(-50%)"
                        />
                      }
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Text
                      textColor="error"
                      textSize="paragraph"
                      m={{ b: "1rem" }}
                    >
                      {touched.password && capitalize(errors.password)}
                    </Text>
                  </Div>
                  <Div>
                    <Input
                      type="password"
                      name="password_confirmation"
                      placeholder="Confirm Password"
                      placeholderTextColor="navy"
                      shadow="2"
                      textColor="blue"
                      h="2.5rem"
                      border="1px solid"
                      borderColor="transparent"
                      borderColor={
                        touched.password_confirmation
                          ? values.password_confirmation
                            ? errors.password_confirmation
                              ? "red"
                              : "oilGreen"
                            : errors.password_confirmation
                            ? "red"
                            : "transparent"
                          : "transparent"
                      }
                      p="0 2.125rem"
                      prefix={
                        <Image
                          src="/name.svg"
                          w="14px"
                          h="14px"
                          pos="absolute"
                          top="50%"
                          left="0.75rem"
                          transform="translateY(-50%)"
                        />
                      }
                      value={values.password_confirmation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Text
                      textColor="error"
                      textSize="paragraph"
                      m={{ b: "1rem" }}
                    >
                      {touched.password_confirmation &&
                        capitalize(errors.password_confirmation)}
                    </Text>
                  </Div>

                  <BlueButton
                    type="submit"
                    m={{ t: { xs: "0.625rem", lg: "0" } }}
                    text={
                      isSubmitting ? (
                        <Icon name="Loading" color="white" size="16px" />
                      ) : (
                        "Update Password"
                      )
                    }
                    padding="0.5rem 1.25rem"
                    textSize="header1"
                    h="2.5rem"
                    w="100%"
                  />
                </Div>
              </form>
            )
          }}
        </Formik>
        <Div
          m={{ t: "2.5rem" }}
          d={{ xs: "block", md: "flex" }}
          maxW={{ md: "15.375rem" }}
        >
          <Button
            rounded="20px"
            cursor="pointer"
            bg="pink"
            shadow="2"
            p="13px 1.25rem"
            w={{ xs: "100%", md: "auto" }}
            m={{ r: { md: "10px" }, b: { xs: "10px", md: "0" } }}
            textTransform="uppercase"
            textColor="blue"
            textWeight="bold"
            fontFamily="secondary"
            onClick={() => toggleTab("account")}
          >
            Back
          </Button>
        </Div>
      </Div>
    </>
  )
}

export default UpdatePassword
