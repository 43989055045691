import React, { useState } from "react"
import axios from "axios"
import { Div, Text, Dropdown, Button, Icon } from "atomize"
import BlueButton from "../common/molecules/BlueButton"
import Calendar from "react-calendar"
import { addDaysToDate } from "../../utilities/helpers"
// const variantOptions = ["Vegan CBD Gummies", "Option 1", "Option 2", "Option 3"]
const quantityOptions = [
  { key: "1", text: "1" },
  { key: "2", text: "2" },
  { key: "3", text: "3" },
  { key: "4", text: "4" },
  { key: "5", text: "5" },
  { key: "6", text: "6" },
  { key: "7", text: "7" },
  { key: "8", text: "8" },
  { key: "9", text: "9" },
  { key: "10", text: "10" },
]
const deliveryOptions = [
  { key: "15", text: "Every 15 days" },
  { key: "30", text: "Every 30 days" },
]

// const menuList = (
//   <Div bg="transparent" p="10px 13px">
//     {options.map((name, index) => (
//       <Text
//         bg="transparent"
//         d="block"
//         p={{ y: "0.25rem" }}
//         onClick={() => setSelectedVariant(name)}
//       >
//         {name}
//       </Text>
//     ))}
//   </Div>
// )

const formatDate = value => {
  const date = new Date(value)
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

const EditSubscription = ({
  setActivePage,
  activeSubscription,
  setActiveSubscription,
  errors,
  setErrors,
  setData,
  data,
}) => {
  const [dropdown, showDropdown] = useState("")
  const [message, setMessage] = useState(null)
  // const [variant, setSelectedVariant] = useState(variantOptions[0])
  const [date, setSelectedDate] = useState(
    new Date(activeSubscription.next_charge_scheduled_at)
  )
  const [calendar, showCalendar] = useState(false)
  const [chargeDate, setChargeDate] = useState()
  const initialQuantity = quantityOptions.find(
    listItem => listItem.key == activeSubscription.quantity
  )
  const intitalDelivery = deliveryOptions.find(
    listItem => listItem.key == activeSubscription.order_interval_frequency
  )
  const [quantity, setSelectedQuantity] = useState(
    initialQuantity ?? quantityOptions[0]
  )
  const [delivery, setSelectedDelivery] = useState(
    intitalDelivery ?? deliveryOptions[0]
  )
  const [formSubmitting, setFormSubmitting] = useState(false)

  const updateSubscription = async (id, updatedFields) => {
    setErrors({})
    try {
      let response = await axios.post("/api/subscriptions/update", {
        subscriptionID: id,
        ...updatedFields,
      })
      const subscriptions = data
      var foundIndex = subscriptions.findIndex(
        subscription => subscription.id == response.data.subscription.id
      )
      subscriptions[foundIndex] = response.data.subscription
      setData(subscriptions)
      setMessage("Your changes have been saved.")
    } catch (error) {
      setErrors({ [id]: error.response.data })
    }
  }

  const menuList = (options, field) => (
    <Div
      bg="white"
      pos="absolute"
      w="100%"
      top="-6px"
      p="10px 13px"
      maxH="10rem"
      overflow="auto"
      border={{ t: "0", x: "1px solid", b: "1px solid" }}
      borderColor="blue"
      rounded={{ b: "20px" }}
    >
      <Div h="1px" bg="blue" m={{ b: "5px" }}></Div>
      {options.map((name, index) => (
        <Text
          bg="transparent"
          d="block"
          p={{ y: "0.25rem" }}
          cursor="pointer"
          onClick={() => {
            // if (field === "variant") setSelectedVariant(name.key)
            // if (field === "date") setSelectedDate(name)
            if (field === "quantity") setSelectedQuantity(name)
            if (field === "delivery") setSelectedDelivery(name)
            showDropdown(false)
          }}
        >
          {name.text}
        </Text>
      ))}
    </Div>
  )

  const toggleDropdown = id => {
    if (dropdown === id) {
      showDropdown("")
    } else {
      showDropdown(id)
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setFormSubmitting(true)
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`
    const data = {
      date: formattedDate,
      delivery: delivery.key,
      quantity: quantity.key,
    }

    await updateSubscription(activeSubscription.id, data)
    setFormSubmitting(false)
  }

  const onDateChange = value => {
    setSelectedDate(value)
    showCalendar(false)
  }

  return (
    <>
      <Div
        p={{
          y: { xs: "1.875rem", lg: "2.5rem" },
          x: { xs: "1.25rem", lg: "2.5rem" },
        }}
        bg="yellow"
        rounded="lg"
        shadow="4"
      >
        <Text
          textWeight="bold"
          textSize="display1"
          fontFamily="secondary"
          p={{ b: { xs: "1.875rem", lg: "2.5rem" } }}
          style={{ lineHeight: "32px" }}
          textAlign={{ xs: "center", lg: "left" }}
        >
          Edit subscription
        </Text>
        {message && (
          <>
            <Div
              border={{ t: "1px solid" }}
              borderColor="borderColor"
              p={{ y: "2.5rem" }}
              d={{ xs: "block", md: "flex" }}
            >
              <Text textColor="success700" m={{ t: "1rem" }}>
                {message}
              </Text>
            </Div>
            <Div d={{ xs: "none", md: "flex" }} m={{ t: "2.5rem" }}>
              <Button
                p="13px 1.25rem"
                bg="transparent"
                border="1px solid"
                borderColor="borderColor"
                fontFamily="secondary"
                style={{ lineHeight: "100%" }}
                rounded="20px"
                textColor="blue"
                m={{ r: "10px" }}
                onClick={() => {
                  setActivePage("home")
                  setActiveSubscription(null)
                }}
                type="button"
              >
                Back
              </Button>
            </Div>
          </>
        )}
        {!message && (
          <form onSubmit={handleSubmit}>
            <Div
              border={{ t: "1px solid" }}
              borderColor="borderColor"
              p={{ y: "2.5rem" }}
              d={{ xs: "block", md: "flex" }}
            >
              <Div flexGrow="1" p={{ r: { md: "3.75rem" } }}>
                <Text
                  textColor="danger700"
                  m={{ t: "1rem" }}
                  opacity={errors[activeSubscription.id] ? "1" : "0"}
                  transition
                >
                  {errors[activeSubscription.id]}
                </Text>
                {/* <Text textWeight="bold">Change Variant</Text>
            <Dropdown
              isOpen={dropdown === "variant"}
              onClick={() => toggleDropdown("variant")}
              menu={menuList(variantOptions, "variant")}
              bg="transparent"
              focusBg="white"
              focusBorderColor="blue"
              className={
                dropdown === "variant"
                  ? "rounded-dropdown"
                  : "rounded-dropdown-closed"
              }
              m={{ t: "10px" }}
              border="1px solid"
              borderColor="blue"
              textColor="blue"
            >
              {variant}
            </Dropdown>
            <Div w="100%" m={{ t: "1.25rem" }}>
              <BlueButton
                text="add product"
                w="100%"
                h="2.5rem"
                textSize="header1"
              />
            </Div> */}

                <Div m={{ t: "1.25rem" }}>
                  <Text textWeight="bold">Change next charge date</Text>
                  <Div
                    onClick={() => showCalendar(!calendar)}
                    bg="transparent"
                    focusBg="white"
                    h="2.5rem"
                    focusBorderColor="blue"
                    m={{ y: "10px" }}
                    border="1px solid"
                    borderColor="blue"
                    textColor="blue"
                    rounded="20px"
                    cursor="pointer"
                    d="flex"
                    align="center"
                    p="0 0.75rem"
                    pos="relative"
                    transition
                  >
                    <Icon
                      name={calendar ? "UpArrow" : "DownArrow"}
                      size="18px"
                      color="blue"
                      pos="absolute"
                      right="0.75rem"
                    />
                    {formatDate(date)}
                  </Div>

                  {calendar ? (
                    <Calendar
                      onChange={onDateChange}
                      value={date}
                      minDate={new Date()}
                      maxDate={addDaysToDate(new Date(), 30)}
                    />
                  ) : null}
                </Div>

                <Div m={{ t: "1.25rem" }}>
                  <Text textWeight="bold">Change Quantity</Text>
                  <Dropdown
                    isOpen={dropdown === "quantity"}
                    onClick={() => toggleDropdown("quantity")}
                    menu={menuList(quantityOptions, "quantity")}
                    bg="transparent"
                    focusBg="white"
                    focusBorderColor="blue"
                    className={
                      dropdown === "quantity"
                        ? "rounded-dropdown"
                        : "rounded-dropdown-closed"
                    }
                    m={{ t: "10px" }}
                    border="1px solid"
                    borderColor="blue"
                    textColor="blue"
                    transition
                    openSuffix={
                      <Icon name="UpArrow" color="blue" size="18px" />
                    }
                    closeSuffix={
                      <Icon name="DownArrow" color="blue" size="18px" />
                    }
                  >
                    {quantity?.text}
                  </Dropdown>
                </Div>

                <Div m={{ t: "1.25rem" }}>
                  <Text textWeight="bold">Change delivery Schedule</Text>
                  <Dropdown
                    isOpen={dropdown === "delivery"}
                    onClick={() => toggleDropdown("delivery")}
                    menu={menuList(deliveryOptions, "delivery")}
                    bg="transparent"
                    focusBg="white"
                    transition
                    focusBorderColor="blue"
                    className={
                      dropdown === "delivery"
                        ? "rounded-dropdown"
                        : "rounded-dropdown-closed"
                    }
                    m={{ t: "10px" }}
                    border="1px solid"
                    borderColor="blue"
                    textColor="blue"
                  >
                    {delivery?.text}
                  </Dropdown>
                </Div>

                <Div d={{ xs: "none", md: "flex" }} m={{ t: "2.5rem" }}>
                  <Button
                    p="13px 1.875rem"
                    bg="pink"
                    fontFamily="secondary"
                    shadow="2"
                    style={{ lineHeight: "100%" }}
                    rounded="20px"
                    textColor="blue"
                    m={{ r: "10px" }}
                    type="submit"
                    disabled={formSubmitting}
                  >
                    save changes
                  </Button>
                  <Button
                    p="13px 1.25rem"
                    bg="transparent"
                    border="1px solid"
                    borderColor="borderColor"
                    fontFamily="secondary"
                    style={{ lineHeight: "100%" }}
                    rounded="20px"
                    textColor="blue"
                    m={{ r: "10px" }}
                    onClick={() => {
                      setActivePage("home")
                      setActiveSubscription(null)
                    }}
                    type="button"
                  >
                    cancel
                  </Button>
                </Div>
              </Div>

              <Div flexGrow="1" m={{ t: { xs: "1.875rem", md: "0" } }}>
                {/* <Text textWeight="bold" m={{ b: "10px" }}>
                Payment Method
              </Text>
              <Text m={{ b: "10px" }}>AMEX ending in 3008 Expires 7/2025</Text>
              <Text textDecor="underline">Update payment info</Text>

              <Div m={{ t: "1.25rem" }}>
                <Text textWeight="bold" m={{ b: "10px" }}>
                  Shipping
                </Text>
                <Text>Khabib Nurmagomedov</Text>
                <Text>1920 canyon road</Text>
                <Text>Dagistan, Russia, 000001</Text>
                <Text>Russia</Text>
                <Text m={{ t: "10px" }} textDecor="underline">
                  Edit address
                </Text>
              </Div> */}

                <Div d={{ xs: "flex", md: "none" }} m={{ t: "2.5rem" }}>
                  <Button
                    p="13px 1.875rem"
                    bg="pink"
                    fontFamily="secondary"
                    shadow="2"
                    style={{ lineHeight: "100%" }}
                    rounded="20px"
                    textColor="blue"
                    m={{ r: "10px" }}
                    onClick={() => setActivePage("home")}
                  >
                    save changes
                  </Button>
                  <Button
                    p="13px 1.25rem"
                    bg="transparent"
                    border="1px solid"
                    borderColor="borderColor"
                    fontFamily="secondary"
                    style={{ lineHeight: "100%" }}
                    rounded="20px"
                    textColor="blue"
                    m={{ r: "10px" }}
                    onClick={() => setActivePage("home")}
                  >
                    cancel
                  </Button>
                </Div>
              </Div>
            </Div>
          </form>
        )}
      </Div>
    </>
  )
}

export default EditSubscription
