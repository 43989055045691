import React from "react"
import { get } from "lodash"
import moment from "moment"
import ls from "local-storage"
import { Text, Div, Button, Anchor, Icon } from "atomize"

import { useQuery } from "@apollo/client"

import { CUSTOMER_ORDERS } from "../graphql/queries"

const ModuleOrdersInfo = () => {
  const {
    data: fetchCustomerOrdersData,
    loading: fetchCustomerOrdersLoading,
    refetch: refetchCustomerOrders,
  } = useQuery(CUSTOMER_ORDERS, {
    skip: !ls("accessToken"),
    variables: { accessToken: ls("accessToken") },
  })

  // TODO: add loading
  if (fetchCustomerOrdersLoading)
    return (
      <Div d="flex" justify="center" align="center" h="50vh">
        <Icon name="Loading" color="blue" size="16px" />
      </Div>
    )

  const orders = get(fetchCustomerOrdersData, "customer.orders.edges", [])

  return (
    <>
      <Div
        p={{
          y: { xs: "1.875rem", lg: "2.5rem" },
          x: { xs: "1.25rem", lg: "2.5rem" },
        }}
        bg="yellow"
        rounded="lg"
        shadow="4"
      >
        <Text
          textWeight="bold"
          textSize="display1"
          fontFamily="secondary"
          p={{ b: { xs: "1.875rem", lg: "2.5rem" } }}
          style={{ lineHeight: "32px" }}
          textAlign={{ xs: "center", lg: "left" }}
        >
          Your orders
        </Text>
        {orders.length === 0 && <Div>No orders found</Div>}
        <Div>
          {orders.map((order, id) => {
            return (
              <Div
                border={{ t: "1px solid" }}
                borderColor="borderColor"
                p={{ y: "2.5rem" }}
              >
                <Div d="flex" justify="space-between">
                  <Text>Order #{order.node.orderNumber} </Text>
                  <Text d={{ xs: "none", md: "block" }}>
                    {moment(order.node.processedAt).format("MMMM Do YYYY")}
                  </Text>
                </Div>
                <Text
                  m={{ t: "10px" }}
                  textWeight="bold"
                  fontFamily="secondary"
                  textSize="header"
                >
                  ${order.node.totalPriceV2.amount}
                </Text>
                <Anchor
                  d="inline-block"
                  href={order.node.statusUrl}
                  rounded="20px"
                  target="_blank"
                  cursor="pointer"
                  bg="pink"
                  shadow="2"
                  p="13px 1.25rem"
                  // w={{ xs: "100%", md: "auto" }}
                  m={{ t: "1.25rem" }}
                  textTransform="uppercase"
                  textColor="blue"
                  textWeight="bold"
                  fontFamily="secondary"
                >
                  Track order
                </Anchor>

                <Div
                  d="flex"
                  //justify={{ xs: "center", lg: "start" }}
                  m={{ t: "1.25rem" }}
                >
                  {get(order, "node.lineItems.edges", []).map(
                    (edge, id) =>
                      edge?.node?.variant?.image?.originalSrc && (
                        <Div
                          key={id}
                          flexGrow="1"
                          bgImg={edge?.node?.variant?.image?.originalSrc}
                          p={{ b: "3.125rem" }}
                          maxW="3.125rem"
                          m={{ r: "10px" }}
                          bgPos="center"
                          bgSize="cover"
                          pos="relative"
                          rounded="lg"
                        ></Div>
                      )
                  )}
                </Div>
              </Div>
            )
          })}
        </Div>
      </Div>
    </>
  )
}

export default ModuleOrdersInfo
