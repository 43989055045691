import React, { useState, useEffect } from "react"
import { Div, Text, Row, Col, Button, Icon } from "atomize"
import axios from "axios"
import { get } from "lodash"
import moment from "moment"

const UpcomingDeliveries = ({
  setActivePage,
  data,
  setActiveDeliveries,
  errors,
  formSubmitting,
  unskipActivedelivery,
  skipActivedelivery 
}) => {
  return (
    <>
      <Div
        p={{
          y: { xs: "1.875rem", lg: "2.5rem" },
          x: { xs: "1.25rem", lg: "2.5rem" },
        }}
        bg="yellow"
        rounded="lg"
        shadow="4"
      >
        <Text
          textWeight="bold"
          textSize="display1"
          fontFamily="secondary"
          p={{ b: { xs: "1.875rem", lg: "2.5rem" } }}
          style={{ lineHeight: "32px" }}
          textAlign={{ xs: "center", lg: "left" }}
        >
          Upcoming Deliveries
        </Text>
        <Div>
          {/* EMPTY STATE */}
          {!data ? 
          (
          <Div
            border={{ t: "1px solid" }}
            borderColor="borderColor"
            p={{ y: "2.5rem" }}
          >
            <Text>You have no upcoming deliveries. Boohoo! 👻</Text>
          </Div>
          ) :(
          <>
          {/* WHEN THERE ARE DELIVERIES */}
          {data?.map(charge => {
            return(
          <Div
            border={{ t: "1px solid" }}
            borderColor="borderColor"
            p={{ y: "2.5rem" }}
          >
            <Text
              textWeight="bold"
              textSize="header"
              m={{ b: "1.25rem" }}
              fontFamily="secondary"
            >
            {moment(charge.scheduled_at).format("MMMM DD YYYY")}
            </Text>
            <Text textWeight="bold" textSize="header" m={{ b: "0.625rem" }}>
              {charge.shipping_address.address1} {charge.shipping_address.city} {charge.shipping_address.zip}
            </Text>
            <Row>
              {charge.line_items?.map((line_item, ind) => {
                return(
                <>
              <Col size={{ xs: "12", lg: "5" }}>
               {ind < 1  ? (<Text m={{ b: "10px" }} textWeight="bold">
                  Product
                </Text>): ''}
                <Div>
                  <Text>{line_item.title}</Text>
                </Div>
              </Col>
              <Col size={{ xs: "12", lg: "7" }}>
                <Div m={{ t: { xs: "10px", md: "0" } }}>
                  <Row
                    d="flex"
                    justify={{ xs: "left", md: "space-between" }}
                    m="0"
                  >
                    <Col
                      p={{ md: "0" }}
                      size={{ xs: "4", md: "3" }}
                      offset={{ md: "7" }}
                    >
                     {ind < 1  ? ( <Text textWeight="bold" m={{ b: "10px" }}>
                        Quantity
                      </Text>): ''}
                      <Text>{line_item.quantity}</Text>
                    </Col>
                    <Col p={{ md: "0" }} size={{ xs: "4", md: "2" }}>
                      {ind < 1  ? (<Text textWeight="bold" m={{ b: "10px" }}>
                        USD
                      </Text>): ''}
                      <Text>{line_item.price}</Text>
                    </Col>
                  </Row>
                </Div>
              </Col>
              {charge.status === "QUEUED" ? (<Div d="flex" m={{ t: "1.25rem" }}>
              <Button
                p="13px 1.875rem"
                bg="pink"
                fontFamily="secondary"
                shadow="2"
                style={{ lineHeight: "100%" }}
                rounded="20px"
                textColor="blue"
                m={{ r: "10px" }}
                onClick={() => {
                  skipActivedelivery(charge.id, line_item.subscription_id)
                 }}
              >
                Skip this period
              </Button>
              <Button
                p="13px 1.25rem"
                bg="transparent"
                border="1px solid"
                borderColor="borderColor"
                fontFamily="secondary"
                style={{ lineHeight: "100%" }}
                rounded="20px"
                textColor="blue"
                m={{ r: "10px" }}
                onClick={() => {
                        setActivePage("edit")
                        setActiveDeliveries(line_item)
                      }}
              >
                edit
              </Button>
            </Div>):(
            <Div>
              <Text textWeight="bold" textColor="error" m={{ t: "10px" }}>
              Skipped
            </Text>
            <Div d="flex" m={{ t: "1.25rem" }}>
              <Button
                p="13px 1.875rem"
                bg="pink"
                fontFamily="secondary"
                shadow="2"
                style={{ lineHeight: "100%" }}
                rounded="20px"
                textColor="blue"
                m={{ r: "10px" }}
                onClick={() => {
                  unskipActivedelivery(charge.id, line_item.subscription_id)
                 }}
              >
                Unskip
              </Button>
            </Div>
            </Div>)
              }
              </>
              )
        })}
            </Row>
          </Div>
          )
        })}
        </>
        )}
        </Div>
      </Div>
    </>
  )
}

export default UpcomingDeliveries
