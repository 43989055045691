import React, { useState, useEffect } from "react"
import { Div, Text, Row, Col, Icon } from "atomize"
import { navigate } from "gatsby"
import queryString from "query-string"
import ModuleAccountInfo from "./ModuleAccountInfo"
import AccountProfiles from "../components/account/AccountProfiles"
import ModuleOrdersInfo from "./ModuleOrdersInfo"
import UpdatePassword from "../components/auth/UpdatePassword"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"
import SubscriptionsContainer from "../components/account/SubscriptionsContainer"
import BillingInfoContainer from "../components/account/BillingInfoContainer"
import DeliveriesContainer from "../components/account/DeliveriesContainer"
import { firePostLogout, fireSubscriptionPage, fireOrdersPage } from "../services/authService"
import { useLocation } from "@reach/router"

import ls from "local-storage"
import axios from "axios"
import { get, capitalize } from "lodash"

const ModuleAccountContainer = () => {
  const location = useLocation()
  const query = queryString.parse(location.search)
  const [tab, setActiveTab] = useState(query.tab || "account")
  const [loading, setLoading] = useState(true)
  const [section, setSection] = useState(true)
  const [subscriptionurl, setSubscriptionurl] = useState(null)
  const [orderurl, setOrderurl] = useState(null)
  const toggleTab = tab => {
    navigate(`/profile?tab=${tab}`)
  }
const fetchCustomers = async () => {
    setLoading(true)
    try {
      let response = await axios.post("/api/customers", { customerEmail: ls('customer_email')})
      const customers = get(response, "data.customers")
     if(customers[0] =='undefined' || customers[0] ==undefined || customers[0] ==''){
        setSection(false)
        setLoading(false)
      }else{
        fetchSubscriptions(customers[0].id, customers[0].hash)
        setSection(true)
      }
    } catch (error) {
      setLoading(false)
    }
  }
  const fetchSubscriptions = async (customerID, customerHash) => {
    setLoading(true)
    try {
      let response = await axios.post("/api/subscriptions", {customerID: customerID})
      const subscriptions = get(response, "data.subscriptions")
      const sub = subscriptions.find(x => x.status == "ACTIVE");
      if(sub =='undefined' || sub ==undefined || sub ==''){
        setSection(false)
        setLoading(false)
      }else{
        setSection(true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchCustomers()
    setActiveTab(query.tab)
  }, [query.tab])
  /**
   * renders active tab
   */
   let sections ={};
   if(section){
     sections ={
      account: {
        key: "account",
        name: "Account",
        component: <div>Account</div>,
      },
      orders: {
        key: "orders",
        name: "Orders",
        component: <div>Orders</div>,
      },
      subscription: {
        key: "subscribe",
        name: "Manage Subscriptions",
        component: "",
      },
      signOut: {
        key: "sign-out",
        name: "Sign Out",
        component: <div>sign out</div>,
      }
    };
   }else{
    sections ={
      account: {
        key: "account",
        name: "Account",
        component: <div>Account</div>,
      },
      orders: {
        key: "orders",
        name: "Orders",
        component: <div>Orders</div>,
      },
      signOut: {
        key: "sign-out",
        name: "Sign Out",
        component: <div>sign out</div>,
      }
    };
   }
   //
  const renderActiveTab = () => {
    switch (tab) {
      case "account":
        return <ModuleAccountInfo toggleTab={toggleTab} />
      case "orders":
       // fireOrdersPage(orderurl)
        return (<Div>
            <ModuleOrdersInfo />
          </Div>)
      case "subscription":
        return null;
      case "subscribe":
        return (
          <Div>
            <SubscriptionsContainer />
          </Div>)
      case "deliveries":
        return (
          <Div>
            <DeliveriesContainer />
          </Div>
        )
      case "billing":
        return <BillingInfoContainer />
      case "sign-out":
        firePostLogout()
        return null
      case "update-password":
        return (
          <Div>
            <UpdatePassword toggleTab={toggleTab} />
          </Div>
        )
      default:
        return <ModuleAccountInfo toggleTab={toggleTab} />
    }
  }
  if (loading)
    return (
      <Div d="flex" justify="center" align="center" h="50vh">
        <Icon name="Loading" color="blue" size="16px" />
      </Div>
    )
  return (
    <>
      <WebsiteContainer>
        <Div>
          <Row m={{t: {lg: '5rem', xs: '3rem'}}}>
            <Col size={{ xs: "12", lg: "4" }}>
              <Div m={{ r: { lg: "2.75rem" }, b: { xs: "2.5rem", lg: "0" } }}>
                <AccountProfiles
                  sections={sections}
                  toggleTab={toggleTab}
                  activeTab={tab}
                />
              </Div>
            </Col>
            <Col size={{ xs: "12", lg: "8" }}>
              {/* <ModuleAccountInfo /> */}
              {/* <ModuleOrdersInfo /> */}
              <Div>{renderActiveTab()}</Div>
            </Col>
          </Row>
        </Div>
      </WebsiteContainer>
    </>
  )
}

export default ModuleAccountContainer
