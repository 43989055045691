import React, { useState, useEffect } from "react"
import { Div, Text, Icon } from "atomize"
import axios from "axios"
import { get } from "lodash"
import BillingInfo from "./BillingInfo"
import EditAddress from "./EditAddress"
import EditCardInfo from "./EditCardInfo"

const BillingInfoContainer = () => {
  const [page, setActivePage] = useState("home")
  const [activeBilling, setActiveBilling] = useState(null)
  const [data, setData] = useState(null)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(true)
  const [formSubmitting, setFormSubmitting] = useState([])

  const fetchbilling = async () => {
    setLoading(true)
    try {
      let response = await axios.get("/api/billing")
      const payment_sources = get(response, "data.payment_sources")

      setData(payment_sources[0])
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchbilling()
  }, [])

  if (loading)
    return (
      <Div d="flex" justify="center" align="center" h="50vh">
        <Icon name="Loading" color="blue" size="16px" />
      </Div>
    )

  return (
    <>
      <Div>
        <Div d={page === "home" ? "block" : "none"}>
          <BillingInfo
            setActivePage={setActivePage}
            data={data}
            setActiveBilling={setActiveBilling}
            errors={errors}
            formSubmitting={formSubmitting}
          />
        </Div>

        <Div d={page === "edit" ? "block" : "none"}>
          {activeBilling && (
            <EditAddress
              setActivePage={setActivePage}
              activeBilling={activeBilling}
              errors={errors}
              setData={setData}
              setErrors={setErrors}
              data={data}
              setActiveBilling={setActiveBilling}
            />
          )}
        </Div>
        <Div d={page === "cartinfo" ? "block" : "none"}>
          {activeBilling && (
            <EditCardInfo
              setActivePage={setActivePage}
              activeBilling={activeBilling}
              errors={errors}
              setData={setData}
              setErrors={setErrors}
              data={data}
              setActiveBilling={setActiveBilling}
            />
          )}
        </Div>
      </Div>
    </>
  )
}

export default BillingInfoContainer
