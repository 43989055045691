import React, { useState, useEffect } from "react"
import { Div, Text, Icon } from "atomize"
import axios from "axios"
import { get } from "lodash"
import UpcomingDeliveries from "./UpcomingDeliveries"
import EditSubscription from "./EditSubscription"

const DeliveriesContainer = () => {
  const [page, setActivePage] = useState("home")
  const [activeDeliveries, setActiveDeliveries] = useState(null)
  const [data, setData] = useState(null)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(true)
  const [formSubmitting, setFormSubmitting] = useState([])

  const fetchDeleiveries = async () => {
    setLoading(true)

    try {
      let response = await axios.get("/api/deliveries")
      const deliveries = get(response, "data.charges")
      setData(deliveries)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const unskipActivedelivery= async (id, subscriptionId) =>{
    setLoading(true)
    setFormSubmitting([id])
    try {
      await axios.post("/api/deliveries/unskip", { chargeID: id, subscriptionId: subscriptionId })
      fetchDeleiveries()
      setFormSubmitting([])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setFormSubmitting([])
      setErrors({ [id]: error.response.data })
    }
  }
  const skipActivedelivery = async (id, subscriptionId) =>{
    setLoading(true)
    //294361520
    setFormSubmitting([id])
    try {
      await axios.post("/api/deliveries/skip", { chargeID: id, subscriptionId: subscriptionId })
      fetchDeleiveries()
      setFormSubmitting([])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setFormSubmitting([])
      setErrors({ [id]: error.response.data })
    }
  }

  useEffect(() => {
    fetchDeleiveries()
  }, [])

  if (loading)
    return (
      <Div d="flex" justify="center" align="center" h="50vh">
        <Icon name="Loading" color="blue" size="16px" />
      </Div>
    )

  return (
    <>
      <Div>
        <Div d={page === "home" ? "block" : "none"}>
          <UpcomingDeliveries
            setActivePage={setActivePage}
            data={data}
            setActiveDeliveries={setActiveDeliveries}
            errors={errors}
            formSubmitting={formSubmitting}
            unskipActivedelivery = {unskipActivedelivery}
            skipActivedelivery = {skipActivedelivery}
          />
        </Div>
        <Div d={page === "edit" ? "block" : "none"}>
          {activeDeliveries && (
            <EditSubscription
              setActivePage={setActivePage}
              activeSubscription={activeDeliveries}
              errors={errors}
              setData={setData}
              setErrors={setErrors}
              data={data}
              setActiveSubscription={setActiveDeliveries}
            />
          )}
        </Div>
      </Div>
    </>
  )
}

export default DeliveriesContainer
